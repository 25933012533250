import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, updateDoc, deleteDoc, doc } from "firebase/firestore";
import { db } from '../firebaseConfig';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Spinner from '../components/Spinner';
import '../css/departmentsform.css';

function DepartmentsForm() {
  const [name, setName] = useState('');
  const [building, setBuilding] = useState('');
  const [address, setAddress] = useState('');
  const [features, setFeatures] = useState('');
  const [nearby, setNearby] = useState('');
  const [rentPrice, setRentPrice] = useState('');
  const [textFeatures, setTextFeatures] = useState('');
  const [photos, setPhotos] = useState([]); // Array de fotos con orden
  const [bedrooms, setBedrooms] = useState('');
  const [bathrooms, setBathrooms] = useState('');
  const [beds, setBeds] = useState('');
  const [persons, setPersons] = useState('');
  const [hasParking, setHasParking] = useState(false);
  const [hasLivingRoom, setHasLivingRoom] = useState(false);
  const [hasKitchen, setHasKitchen] = useState(false);
  const [hasDiningRoom, setHasDiningRoom] = useState(false);
  const [mapLink, setMapLink] = useState('');
  const [departments, setDepartments] = useState([]);
  const [editId, setEditId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const storage = getStorage();

  useEffect(() => {
    fetchDepartments();
  }, []);

  const fetchDepartments = async () => {
    setLoading(true);
    try {
      const querySnapshot = await getDocs(collection(db, "departments"));
      const departmentsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setDepartments(departmentsList);
    } catch (error) {
      NotificationManager.error('Error al cargar los departamentos', 'Error');
    } finally {
      setLoading(false);
    }
  };

  const handlePhotoUpload = async (file, order) => {
    const storageRef = ref(storage, `departments/${file.name}`);
    const uploadTask = await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(uploadTask.ref);
    return { url: downloadURL, order };
  };

  const handlePhotosChange = async (e) => {
    const files = Array.from(e.target.files);
    const photoUploads = await Promise.all(files.map((file, index) =>
      handlePhotoUpload(file, photos.length + index + 1)
    ));
    setPhotos([...photos, ...photoUploads]);
  };

  const handlePhotoOrderChange = (index, newOrder) => {
    const updatedPhotos = [...photos];
    updatedPhotos[index].order = newOrder;
    setPhotos(updatedPhotos.sort((a, b) => a.order - b.order));
  };

  const handlePhotoDelete = async (index) => {
    const photoToDelete = photos[index];
    if (photoToDelete.url) {
      const photoRef = ref(storage, photoToDelete.url);
      await deleteObject(photoRef);
    }
    const updatedPhotos = photos.filter((_, i) => i !== index);
    setPhotos(updatedPhotos);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const departmentData = {
      name,
      building,
      address,
      features,
      nearby,
      rentPrice,
      textFeatures,
      photos,
      bedrooms,
      bathrooms,
      beds,
      persons,
      hasParking,
      hasLivingRoom,
      hasKitchen,
      hasDiningRoom,
      mapLink,
    };

    try {
      if (editId) {
        const departmentRef = doc(db, "departments", editId);
        await updateDoc(departmentRef, departmentData);
        NotificationManager.success('Departamento actualizado exitosamente', 'Éxito');
      } else {
        await addDoc(collection(db, "departments"), departmentData);
        NotificationManager.success('Departamento guardado exitosamente', 'Éxito');
      }
      fetchDepartments();
      resetForm();
    } catch (e) {
      NotificationManager.error('Error al guardar el departamento', 'Error');
    } finally {
      setLoading(false);
      setShowForm(false);
    }
  };

  const handleEdit = (department) => {
    setEditId(department.id);
    setName(department.name);
    setBuilding(department.building);
    setAddress(department.address);
    setFeatures(department.features);
    setNearby(department.nearby);
    setRentPrice(department.rentPrice);
    setTextFeatures(department.textFeatures);
    setBedrooms(department.bedrooms);
    setBathrooms(department.bathrooms);
    setBeds(department.beds);
    setPersons(department.persons);
    setHasParking(department.hasParking);
    setHasLivingRoom(department.hasLivingRoom);
    setHasKitchen(department.hasKitchen);
    setHasDiningRoom(department.hasDiningRoom);
    setMapLink(department.mapLink || '');
    setPhotos(department.photos || []);
    setShowForm(true);
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await deleteDoc(doc(db, "departments", id));
      NotificationManager.success('Departamento eliminado exitosamente', 'Éxito');
      fetchDepartments();
    } catch (e) {
      NotificationManager.error('Error al eliminar el departamento', 'Error');
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setEditId(null);
    setName('');
    setBuilding('');
    setAddress('');
    setFeatures('');
    setNearby('');
    setRentPrice('');
    setTextFeatures('');
    setPhotos([]);
    setBedrooms('');
    setBathrooms('');
    setBeds('');
    setPersons('');
    setHasParking(false);
    setHasLivingRoom(false);
    setHasKitchen(false);
    setHasDiningRoom(false);
    setMapLink('');
  };

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <div>
          <button className="add-department-button" onClick={() => { setShowForm(true); resetForm(); }}>
            Agregar Departamento
          </button>

          {showForm && (
            <form className="departments-form" onSubmit={handleSubmit}>
              <h2>{editId ? "Editar Departamento" : "Agregar Departamento"}</h2>
              <input 
                type="text" 
                placeholder="Nombre" 
                value={name} 
                onChange={(e) => setName(e.target.value)} 
                required 
              />
              <input 
                type="text" 
                placeholder="Edificio" 
                value={building} 
                onChange={(e) => setBuilding(e.target.value)} 
                required 
              />
              <input 
                type="text" 
                placeholder="Dirección" 
                value={address} 
                onChange={(e) => setAddress(e.target.value)} 
                required 
              />
              <input 
                type="text" 
                placeholder="Características (iconos)" 
                value={features} 
                onChange={(e) => setFeatures(e.target.value)} 
              />
              <input 
                type="text" 
                placeholder="Cercano a" 
                value={nearby} 
                onChange={(e) => setNearby(e.target.value)} 
                required 
              />
              <input 
                type="text" 
                placeholder="Valor arriendo por noche" 
                value={rentPrice} 
                onChange={(e) => setRentPrice(e.target.value)} 
                required 
              />
              <textarea 
                placeholder="Características (texto)" 
                value={textFeatures} 
                onChange={(e) => setTextFeatures(e.target.value)} 
                required 
              />
              <div className="photo-upload-container">
                <input 
                  type="file" 
                  multiple 
                  onChange={handlePhotosChange} 
                />
                <p>Sube fotos y establece el orden. (Arrastra para cambiar el orden)</p>
                <ul className="photo-list">
                  {photos.map((photo, index) => (
                    <li key={index} className="photo-item">
                      <img src={photo.url} alt={`Foto ${index + 1}`} />
                      <input 
                        type="number" 
                        value={photo.order} 
                        onChange={(e) => handlePhotoOrderChange(index, Number(e.target.value))} 
                        className="photo-order-input"
                      />
                      <button type="button" onClick={() => handlePhotoDelete(index)}>Eliminar</button>
                    </li>
                  ))}
                </ul>
              </div>
              <input 
                type="number" 
                placeholder="Cantidad de dormitorios" 
                value={bedrooms} 
                onChange={(e) => setBedrooms(e.target.value)} 
                required 
              />
              <input 
                type="number" 
                placeholder="Cantidad de baños" 
                value={bathrooms} 
                onChange={(e) => setBathrooms(e.target.value)} 
                required 
              />
              <input 
                type="number" 
                placeholder="Cantidad de camas" 
                value={beds} 
                onChange={(e) => setBeds(e.target.value)} 
                required 
              />
              <input 
                type="number" 
                placeholder="Cantidad de Personas" 
                value={persons} 
                onChange={(e) => setPersons(e.target.value)} 
                required 
              />
              <input 
                type="text" 
                placeholder="Link del mapa" 
                value={mapLink} 
                onChange={(e) => setMapLink(e.target.value)} 
                required 
              />
              <div className="checkbox-container">
                <label>
                  <input 
                    type="checkbox" 
                    checked={hasParking} 
                    onChange={(e) => setHasParking(e.target.checked)} 
                  />
                  Estacionamiento
                </label>
                <label>
                  <input 
                    type="checkbox" 
                    checked={hasLivingRoom} 
                    onChange={(e) => setHasLivingRoom(e.target.checked)} 
                  />
                  Living
                </label>
                <label>
                  <input 
                    type="checkbox" 
                    checked={hasKitchen} 
                    onChange={(e) => setHasKitchen(e.target.checked)} 
                  />
                  Cocina
                </label>
                <label>
                  <input 
                    type="checkbox" 
                    checked={hasDiningRoom} 
                    onChange={(e) => setHasDiningRoom(e.target.checked)} 
                  />
                  Comedor
                </label>
              </div>
              <button type="submit">{editId ? "Actualizar" : "Guardar"}</button>
              {editId && <button type="button" className="btnCancelar" onClick={resetForm}>Cancelar</button>}
            </form>
          )}

          <div className="departments-list">
            <div className="centered-div">
              <h2>Lista de Departamentos</h2>
              <ul>
                {departments.map(department => (
                  <li key={department.id}>
                    <h3>{department.name}</h3>
                    <p><strong>Edificio:</strong> {department.building}</p>
                    <p><strong>Dirección:</strong> {department.address}</p>
                    <p><strong>Características:</strong> {department.features}</p>
                    <p><strong>Cercano a:</strong> {department.nearby}</p>
                    <p><strong>Valor Arriendo:</strong> {department.rentPrice}</p>
                    <p><strong>Características (texto):</strong> {department.textFeatures}</p>
                    <p><strong>Dormitorios:</strong> {department.bedrooms}</p>
                    <p><strong>Baños:</strong> {department.bathrooms}</p>
                    <p><strong>Camas:</strong> {department.beds}</p>
                    <p><strong>Personas:</strong> {department.persons}</p>
                    <p><strong>Estacionamiento:</strong> {department.hasParking ? 'Sí' : 'No'}</p>
                    <p><strong>Living:</strong> {department.hasLivingRoom ? 'Sí' : 'No'}</p>
                    <p><strong>Cocina:</strong> {department.hasKitchen ? 'Sí' : 'No'}</p>
                    <p><strong>Comedor:</strong> {department.hasDiningRoom ? 'Sí' : 'No'}</p>
                    <p><strong>Link del mapa:</strong> <a href={department.mapLink} target="_blank" rel="noopener noreferrer">{department.mapLink}</a></p>
                    <div className="photos">
                      {department.photos && department.photos.map((photo, index) => (
                        <div key={index}>
                          <img src={photo.url} alt={`Foto ${index + 1}`} />
                          <p>Orden: {photo.order}</p>
                        </div>
                      ))}
                    </div>
                    <div className="actions">
                      <button onClick={() => handleEdit(department)}>Editar</button>
                      <button onClick={() => handleDelete(department.id)}>Eliminar</button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DepartmentsForm;
