import React from 'react';
import { FaWhatsapp, FaInstagram, FaEnvelope } from 'react-icons/fa';
import '../css/contact.css'; // Importa el archivo CSS

function Contact() {
  return (
    <div className="contact-container">
      <h2>¡Contáctanos a través de nuestras redes sociales!</h2>
      <div className="social-buttons">
        <a href="https://wa.me/+56962364237" target="_blank" rel="noopener noreferrer" className="whatsapp-button">
          <FaWhatsapp /> WhatsApp
        </a>
        <a href="https://www.instagram.com/deptos.granados" target="_blank" rel="noopener noreferrer" className="instagram-button">
          <FaInstagram /> Instagram
        </a>
        <button className="gmail-button">
          <FaEnvelope /> Gmail
        </button>
      </div>
    </div>
  );
}

export default Contact;
