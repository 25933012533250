import React, { useState } from 'react';
import { collection, addDoc } from "firebase/firestore";
import { db } from '../firebaseConfig';
import '../css/reviews.css';
import { FaStar } from 'react-icons/fa';
import ReCAPTCHA from 'react-google-recaptcha';
import Spinner from '../components/Spinner'; // Importa el Spinner

function Reviews() {
  const [name, setName] = useState('');
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [loading, setLoading] = useState(false); // Estado para el spinner

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!captchaVerified) {
      alert("Por favor, verifica que eres humano.");
      return;
    }

    setLoading(true); // Muestra el spinner

    try {
      await addDoc(collection(db, "reviews"), {
        name,
        rating,
        comment,
        createdAt: new Date(),
        status: 'pending' // Estado inicial de la reseña
      });
      alert("Reseña enviada exitosamente.");
      setName('');
      setRating(0);
      setComment('');
    } catch (error) {
      console.error("Error al enviar la reseña: ", error);
      alert("Hubo un error al enviar la reseña.");
    } finally {
      setLoading(false); // Oculta el spinner después de enviar
    }
  };

  const onCaptchaChange = (value) => {
    setCaptchaVerified(!!value);
  };

  return (
    <div className="reviews-container">
      <h1>Deja tu reseña</h1>
      {loading ? (
        <Spinner /> // Muestra el spinner mientras se carga o envía la reseña
      ) : (
        <form className="reviews-form" onSubmit={handleSubmit}>
          <input 
            type="text" 
            placeholder="Tu nombre" 
            value={name} 
            onChange={(e) => setName(e.target.value)} 
            className="reviews-input" 
            required 
            maxLength={100} // Limita el nombre a 100 caracteres
          />
          <h2>Calificación:</h2>
          <div className="reviews-stars">
            {[...Array(5)].map((star, index) => {
              const ratingValue = index + 1;
              return (
                <FaStar 
                  key={index}
                  className="star"
                  color={ratingValue <= rating ? "#ffc107" : "#e4e5e9"}
                  size={30}
                  onClick={() => setRating(ratingValue)}
               
                />
              );
            })}
          </div>
          <textarea 
            placeholder="Escribe tu reseña aquí..." 
            value={comment} 
            onChange={(e) => setComment(e.target.value)} 
            className="reviews-textarea" 
            required 
            maxLength={700} // Limita la reseña a 700 caracteres
          ></textarea>
          <ReCAPTCHA
            sitekey="6Lcu3h4qAAAAALkd5LaUH7re3R0yGXi0FxTAytWx"
            onChange={onCaptchaChange}
          />
          <button type="submit">Enviar</button>
        </form>
      )}
    </div>
  );
}

export default Reviews;
