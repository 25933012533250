import React, { useEffect, useState } from 'react';
import { collection, getDocs } from "firebase/firestore";
import { db } from '../firebaseConfig';
import '../css/home.css'; 
import ahorro2 from '../images/ah.jpg';
import mante from '../images/ma.jpg';
import confi from '../images/co.jpg';
import DepartmentCarousel from '../components/DepartmentCarousel';
import ImageCarousel from '../components/ImageCarousel';
import { FaBed, FaBath, FaCar, FaCouch, FaUtensils, FaStar } from 'react-icons/fa';
import { IoBed } from "react-icons/io5";
import { MdKitchen } from "react-icons/md";
import Spinner from '../components/Spinner';
import { useNavigate } from 'react-router-dom'; // Importa el hook useNavigate

function Home() {
  const navigate = useNavigate(); // Inicializa useNavigate
  function formatPrice(value) {
    return new Intl.NumberFormat('es-CL', {
      style: 'currency',
      currency: 'CLP',
      minimumFractionDigits: 0
    }).format(value);
  }
  
  const [expanded, setExpanded] = useState(null);
  const [loading, setLoading] = useState(true);
  const [departamentos, setDepartamentos] = useState([]);
  const [reviews, setReviews] = useState([]);

  const faqs = [
    {
      question: "Requisitos para arrendar",
      answer: "Debes presentar una copia de tu identificación, un comprobante de ingresos y un depósito de garantía equivalente a un mes de arriendo."
    },
    {
      question: "¿Acepta mascotas?",
      answer: "Sí, aceptamos mascotas en la mayoría de nuestros departamentos. Sin embargo, es posible que se requiera un depósito adicional."
    },
    {
      question: "Horario de check-in y check-out",
      answer: "El check-in es a partir de las 14:00 y el check-out es hasta las 12:00. Podemos ser flexibles si no hay reservas inmediatas antes o después de tu estancia."
    },
    {
      question: "¿Puedo ingresar más personas desde que lo arrendé?",
      answer: "Sí, pero debes informar al propietario con anticipación y puede aplicarse un cargo adicional por persona extra."
    },
  ];

  const toggleExpand = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  useEffect(() => {
    const fetchDepartments = async () => {
      setLoading(true);
      try {
        const querySnapshot = await getDocs(collection(db, "departments"));
        const departmentsList = querySnapshot.docs.map(doc => doc.data());
        setDepartamentos(departmentsList);
      } catch (error) {
        console.error("Error fetching departments: ", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchReviews = async () => {
      try {
        const reviewsSnapshot = await getDocs(collection(db, "reviews"));
        const reviewsList = reviewsSnapshot.docs
          .map(doc => doc.data())
          .filter(review => review.status === 'approved'); // Filtra solo las reseñas aprobadas
        setReviews(reviewsList.slice(0, 6)); // Limita a las primeras 6 reseñas aprobadas
      } catch (error) {
        console.error("Error fetching reviews: ", error);
      }
    };

    fetchDepartments();
    fetchReviews();
  }, []);

  const openImageViewer = (images, index) => {
    // Lógica para abrir el visor de imágenes
  };

  const handleClick = () => {
    navigate('/departamentos'); // Redirige a la página de departamentos
  };

  return (
    <div className="home-container">
      {loading ? (
        <Spinner />
      ) : (
        <>
          <h1 className="home-title">Nuestros departamentos</h1>
          
          <div className="departments-container">
            <DepartmentCarousel autoSlide={true} autoSlideInterval={5000}>
              {departamentos.map((dept, index) => (
                <div key={index} className="dept-item">
                  <div className="dept-content">
                    <div className="dept-info" onClick={handleClick}>
                      <label className='lblTituloTarjetaDepto'>{dept.name}</label>
                      <label className='lblDetalleDepto'>{dept.features} Información extra por definir, para revisar en conjunto con el arrendador...</label>
                      <div className="dept-amenities">
                        <div className="amenity-item">
                          <FaBed className="amenity-icon" />
                          <span>{dept.bedrooms} {dept.bedrooms > 1 ? "dormitorios" : "dormitorio"}</span>
                        </div>
                        <div className="amenity-item">
                          <FaBath className="amenity-icon" />
                          <span>{dept.bathrooms} {dept.bathrooms > 1 ? "baños" : "baño"}</span>
                        </div>
                        <div className="amenity-item">
                          <IoBed className="amenity-icon" />
                          <span>{dept.beds} {dept.beds > 1 ? "camas" : "cama"}</span>
                        </div>
                        {dept.hasParking && (
                          <div className="amenity-item">
                            <FaCar className="amenity-icon" />
                            <span>Estacionamiento</span>
                          </div>
                        )}
                        {dept.hasLivingRoom && (
                          <div className="amenity-item">
                            <FaCouch className="amenity-icon" />
                            <span>Living</span>
                          </div>
                        )}
                        {dept.hasKitchen && (
                          <div className="amenity-item">
                            <MdKitchen className="amenity-icon" />
                            <span>Cocina</span>
                          </div>
                        )}
                        {dept.hasDiningRoom && (
                          <div className="amenity-item">
                            <FaUtensils className="amenity-icon" />
                            <span>Comedor</span>
                          </div>
                        )}
                      </div>

                      <label className='lblPrecioDepto'>{dept.rentPrice}</label>
                      <label className='lblEdificioDepto'>Edificio: {dept.building}</label>
                      <label className='lblDireccionDepto'>Ubicado en {dept.address}</label>
                      <label className='lblCercanoDepto'>Cercano a {dept.nearby}</label>
                    </div>
                    <div className="dept-carousel">
                      {dept.photos && dept.photos.length > 0 && (
                        <ImageCarousel 
                          images={dept.photos.map(photo => photo.url)}  
                          autoSlide={true}       
                          autoSlideInterval={2000} 
                          onImageClick={() => openImageViewer(dept.photos.map(photo => photo.url), 0)} // Conecta correctamente la función onImageClick
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </DepartmentCarousel>
          </div>

          <h2 className="home-title">¿Por qué arrendar con nosotros?</h2>
          
          <div className="why-rent-container">
            <div className="reason-item">
              <img src={ahorro2} alt="Razon 1" className="reason-image"/>
              <label className='lblTitulomotivosDepto'>Sin comisiones</label>
              <label className='lblEdificioDepto'>Ahorra dinero al transferir directamente con el arrendador!</label>
            </div>
            <div className="reason-item">
              <img src={mante} alt="Razon 2" className="reason-image"/>
              <label className='lblTitulomotivosDepto'>Mantenimiento y Cuidado Directo</label>
              <label className='lblEdificioDepto'>Como propietario, me preocupo por el estado de mis departamentos y me aseguro que estén bien mantenidos</label>
            </div>
            <div className="reason-item">
              <img src={confi} alt="Razon 3" className="reason-image"/>
              <label className='lblTitulomotivosDepto'>Trato Transparente y Confiable</label>
              <label className='lblEdificioDepto'>Al no haber intermediarios, las comunicaciones son más directas y claras</label>
            </div>
          </div>

          <h2 className="home-title">Reseñas</h2>
          
          <div className="reviews-container">
            {reviews.map((review, index) => (
              <div key={index} className="review-item">
                <p className='lblTitulomotivosDepto'>Usuario: {review.name} </p> <label className='lblEdificioDepto'>Comentó: {review.comment} </label>
                <div className="reviews-stars">
                  {[...Array(5)].map((star, i) => (
                    <FaStar
                      key={i}
                      className="star"
                      color={i < review.rating ? "#ffc107" : "#e4e5e9"}
                      size={20}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
          <h2 className="home-title">Preguntas frecuentes</h2>
          
          <div className="reviews-container">
            {faqs.map((faq, index) => (
              <div key={index} className="faq-item">
                <button className="faq-question" onClick={() => toggleExpand(index)}>
                  {faq.question}
                </button>
                {expanded === index && (
                  <div className="faq-answer">
                    <p>{faq.answer}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default Home;
