import React from 'react';
import '../css/imageCarousel.css';

function ImageCarousel({ images, autoSlide = false, autoSlideInterval = 3000, onImageClick }) {
  const [currentIndex, setCurrentIndex] = React.useState(0);

  React.useEffect(() => {
    if (autoSlide) {
      const slideInterval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, autoSlideInterval);

      return () => clearInterval(slideInterval);
    }
  }, [autoSlide, autoSlideInterval, images.length]);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div className="image-carousel">
      <button onClick={prevSlide} className="image-carousel-control image-prev">‹</button>
      <div className="image-carousel-track" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        {images.map((image, idx) => (
          <div 
            key={idx} 
            className="image-carousel-slide"
            onClick={() => onImageClick(idx)} // Llama a onImageClick cuando se hace clic en la imagen
          >
            <img src={image} alt={`Slide ${idx + 1}`} className="image-carousel-image" />
          </div>
        ))}
      </div>
      <button onClick={nextSlide} className="image-carousel-control image-next">›</button>
    </div>
  );
}

export default ImageCarousel;
