import React, { useEffect, useState } from 'react';
import { collection, getDocs } from "firebase/firestore";
import { db } from '../firebaseConfig';
import '../css/departments.css';
import { FaBed, FaBath, FaCar, FaCouch, FaUtensils, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { IoBed } from "react-icons/io5";
import { MdKitchen } from "react-icons/md";
import ImageViewer from '../components/ImageViewer';
import { FaWhatsapp } from 'react-icons/fa';
import { IoPerson } from "react-icons/io5";
import ImageCarousel from '../components/ImageCarousel';
import Spinner from '../components/Spinner';

function Departments() {
  const [departamentos, setDepartamentos] = useState([]);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [currentImages, setCurrentImages] = useState([]);
  const [expanded, setExpanded] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "departments"));
        const departmentsList = querySnapshot.docs.map(doc => doc.data());
        setDepartamentos(departmentsList);
      } catch (error) {
        console.error("Error fetching departments: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDepartments();
  }, []);

  function formatPrice(value) {
    return new Intl.NumberFormat('es-CL', {
      style: 'currency',
      currency: 'CLP',
      minimumFractionDigits: 0
    }).format(value);
  }

  const openImageViewer = (images, index) => {
    setCurrentImages(images);
    setPhotoIndex(index);
    setIsViewerOpen(true);
  };

  const toggleExpand = (index) => {
    setExpanded(prev => ({ ...prev, [index]: !prev[index] }));
  };

  return (
    <div className="deptosPage-container">
      {loading ? (
        <Spinner />
      ) : (
        <>
          <h1 className="home-title">Todos nuestros departamentos</h1>
          <div className="departmentos-container">
            {departamentos.map((dept, index) => (
              <div key={index} className="depto-item">
                <div className="depto-info">
                  <h2>{dept.name}</h2>
                  <label className='lblDetalleDepto'>{dept.textFeatures}</label>
                  <div className="depto-amenities">
                    <div className="Deptoamenity-item">
                      <FaBed className="Deptoamenity-icon" />
                      <span>{dept.bedrooms} {dept.bedrooms > 1 ? "dormitorios" : "dormitorio"}</span>
                    </div>
                    <div className="Deptoamenity-item">
                      <FaBath className="Deptoamenity-icon" />
                      <span>{dept.bathrooms} {dept.bathrooms > 1 ? "baños" : "baño"}</span>
                    </div>
                    <div className="Deptoamenity-item">
                      <IoBed className="Deptoamenity-icon" />
                      <span>{dept.beds} {dept.beds > 1 ? "camas" : "cama"}</span>
                    </div>
                    <div className="Deptoamenity-item">
                      <IoPerson className="Deptoamenity-icon" />
                      <span>{dept.persons} personas</span>
                    </div>
                    {dept.hasParking && (
                      <div className="Deptoamenity-item">
                        <FaCar className="Deptoamenity-icon" />
                        <span>Estacionamiento</span>
                      </div>
                    )}
                    {dept.hasLivingRoom && (
                      <div className="Deptoamenity-item">
                        <FaCouch className="Deptoamenity-icon" />
                        <span>Living</span>
                      </div>
                    )}
                    {dept.hasKitchen && (
                      <div className="Deptoamenity-item">
                        <MdKitchen className="Deptoamenity-icon" />
                        <span>Cocina</span>
                      </div>
                    )}
                    {dept.hasDiningRoom && (
                      <div className="Deptoamenity-item">
                        <FaUtensils className="Deptoamenity-icon" />
                        <span>Comedor</span>
                      </div>
                    )}
                  </div>
                  <p><label className='lblPrecioDepto'>Valor por noche:</label> <label className='lblPrecioDepto'>{dept.rentPrice}</label></p>
                  <p><label className='lblEdificioDepto'>Edificio:</label> {dept.building}</p>
                  <p><label className='lblEdificioDepto'>Dirección:</label> {dept.address}</p>
                  <p><label className='lblEdificioDepto'>Cercano a:</label> {dept.nearby}</p>
                  
                  <div className="schedule-button-container">
                    <h2>Reserva aquí a través de Whatsapp!</h2>
                    <button className="schedule-button">
                      <FaWhatsapp className="whatsapp-icon" /> Reservar
                    </button>
                  </div>
                </div>
                <div className="depto-content">
                  {dept.photos && dept.photos.length > 0 && (
                    <ImageCarousel 
                      images={dept.photos.sort((a, b) => a.order - b.order).map(photo => photo.url)}  // Ordena y extrae las URLs de las fotos
                      autoSlide={false} // Desactiva el auto-slide para este carrusel
                      onImageClick={(index) => openImageViewer(dept.photos.map(photo => photo.url), index)} // Abre el visor al hacer clic en la imagen
                    />
                  )}
                </div>
                <div className="toggle-content">
                  <button onClick={() => toggleExpand(index)} className="toggle-button">
                    {expanded[index] ? <FaChevronUp /> : <FaChevronDown />}
                  </button>
                  {expanded[index] && (
                    <div className="extra-content">
                      <div className="map-container">
                        <h2>Ubicación departamento</h2>
                        {dept.mapLink ? (
                          <iframe 
                            src={dept.mapLink}
                            width="100%"
                            height="450"
                            style={{ border: '0' }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                            title={`Mapa de ${dept.name}`}
                          ></iframe>
                        ) : (
                          <p>No se proporcionó un enlace de mapa.</p>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
            {isViewerOpen && (
              <ImageViewer
                images={currentImages}
                currentIndex={photoIndex}
                onClose={() => setIsViewerOpen(false)}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default Departments;
