import React from 'react';
import '../css/location.css';

function Location() {
  return (
    <div className="deptosPage-container">
      <h1 className="home-title">Ubicación de nuestros departamentos</h1>
      <iframe
        title="Google Maps"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3329.1464221236793!2d-70.64585692362412!3d-33.4454915733914!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c5756fc2f769%3A0xef5e16e2a9f62d5d!2sGranados%20535%2C%208330125%20Santiago%2C%20Regi%C3%B3n%20Metropolitana!5e0!3m2!1sen!2scl!4v1722711628342!5m2!1sen!2scl"
        width="100%"
        height="600px"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
      ></iframe>
    </div>
  );
}

export default Location;
