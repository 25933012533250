import React from 'react';
import '../css/imageViewer.css'; // Asegúrate de crear un archivo CSS para el estilo del visor

function ImageViewer({ images, currentIndex, onClose }) {
  const [currentImage, setCurrentImage] = React.useState(currentIndex);

  const nextImage = () => {
    setCurrentImage((currentImage + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImage((currentImage - 1 + images.length) % images.length);
  };

  return (
    <div className="image-viewer-overlay">
      <div className="image-viewer-content">
        <span className="close-button" onClick={onClose}>×</span>
        <img src={images[currentImage]} alt={`Slide ${currentImage + 1}`} />
        <div className="navigation">
          <button onClick={prevImage}>&lt;</button>
          <button onClick={nextImage}>&gt;</button>
        </div>
      </div>
    </div>
  );
}

export default ImageViewer;
