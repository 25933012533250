import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import './App.css';
import Home from './views/Home';
import Departments from './views/Departments';
import Location from './views/Location';
import Reviews from './views/Reviews';
import Contact from './views/Contact';
import DepartmentsForm from './views/DepartmentsForm';
import ReviewsForm from './views/ReviewsForm';
import Login from './views/Login';
import logo from './images/logo1.jpeg';
import { auth } from './firebaseConfig';
import { onAuthStateChanged } from "firebase/auth";
import { FaWhatsapp, FaInstagram, FaBars } from 'react-icons/fa';

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const [user, setUser] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768 && menuOpen) {
        setMenuOpen(false); // Cierra el menú si la pantalla es más grande que 768px
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [menuOpen]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <nav className="navbar">
        <div className="navbar-logo">
          <img src={logo} alt="Logo" />
        </div>
        <div className="hamburger" onClick={toggleMenu}>
          <FaBars />
        </div>
        <ul className={`navbar-links ${menuOpen ? 'active' : ''}`}>
          <li><Link to="/" className={location.pathname === '/' ? 'active-link' : ''} onClick={toggleMenu}>Inicio</Link></li>
          <li><Link to="/departamentos" className={location.pathname === '/departamentos' ? 'active-link' : ''} onClick={toggleMenu}>Departamentos</Link></li>
          <li><Link to="/ubicacion" className={location.pathname === '/ubicacion' ? 'active-link' : ''} onClick={toggleMenu}>Ubicación</Link></li>
          <li><Link to="/resenas" className={location.pathname === '/resenas' ? 'active-link' : ''} onClick={toggleMenu}>Reseñas</Link></li>
          <li><Link to="/contacto" className={location.pathname === '/contacto' ? 'active-link' : ''} onClick={toggleMenu}>Contacto</Link></li>
          {user ? (
            <>
              <li><Link to="/mantenedor" className={location.pathname === '/mantenedor' ? 'active-link' : ''} onClick={toggleMenu}>Mantenedor</Link></li>
              <li><Link to="/revisar-resenas" className={location.pathname === '/revisar-resenas' ? 'active-link' : ''} onClick={toggleMenu}>Revisar Reseñas</Link></li>
            </>
          ) : (
            <li><Link to="/login" className={location.pathname === '/login' ? 'active-link' : ''} onClick={toggleMenu}>Login</Link></li>
          )}
        </ul>
      </nav>

      <div className="container">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/departamentos" element={<Departments />} />
          <Route path="/ubicacion" element={<Location />} />
          <Route path="/resenas" element={<Reviews />} />
          <Route path="/contacto" element={<Contact />} />
          <Route path="/mantenedor" element={user ? <DepartmentsForm /> : <Login setUser={setUser} />} />
          <Route path="/revisar-resenas" element={user ? <ReviewsForm /> : <Login setUser={setUser} />} />
          <Route path="/login" element={<Login setUser={setUser} />} />
        </Routes>
      </div>
      <footer className="footer">
        <div className="footer-left">
          <a href="https://wa.me/+56962364237" target="_blank" rel="noopener noreferrer">
            <FaWhatsapp style={{ marginRight: '8px', fontSize: '1.5em' }} /> WhatsApp
          </a>
          <a href="https://instagram.com/deptos.granados" target="_blank" rel="noopener noreferrer">
            <FaInstagram style={{ marginRight: '8px', fontSize: '1.5em' }} /> Instagram
          </a>
        </div>
        <div className="footer-right">
          <Link to="/contacto">Contacto</Link>
          <Link to="/">Inicio</Link>
        </div>
      </footer>
    </>
  );
}

export default App;
