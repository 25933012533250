import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Configuración de Firebase para tu aplicación
const firebaseConfig = {
  apiKey: "AIzaSyCVZHrT0ox2ePvwsajOx7W7dcNDL-SqA90",
  authDomain: "deptosgranados-e990f.firebaseapp.com",
  projectId: "deptosgranados-e990f",
  storageBucket: "deptosgranados-e990f.appspot.com",
  messagingSenderId: "817973297997",
  appId: "1:817973297997:web:2670037e58ddb76baced04",
  measurementId: "G-WX2ZPQRXHG"
};

// Inicializar Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);
const db = getFirestore(app);



export { auth, db };
