import React, { useEffect, useState } from 'react';
import { collection, getDocs, doc, updateDoc, deleteDoc } from "firebase/firestore";
import { db } from '../firebaseConfig';
import { List, Card, Button, Spin } from 'antd'; // Importa los componentes de Ant Design
import '../css/reviewsForm.css';

function ReviewsManager() {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchReviews = async () => {
      setLoading(true);
      try {
        const querySnapshot = await getDocs(collection(db, "reviews"));
        const reviewsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setReviews(reviewsList);
      } catch (error) {
        console.error("Error fetching reviews: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, []);

  const approveReview = async (id) => {
    try {
      await updateDoc(doc(db, "reviews", id), { status: 'approved' });
      setReviews(reviews.map(review => review.id === id ? { ...review, status: 'approved' } : review));
    } catch (error) {
      console.error("Error approving review: ", error);
    }
  };

  const deleteReview = async (id) => {
    try {
      await deleteDoc(doc(db, "reviews", id));
      setReviews(reviews.filter(review => review.id !== id));
    } catch (error) {
      console.error("Error deleting review: ", error);
    }
  };

  return (
    <div className="reviews-manager-container">
      <div className='divTitulo'> <label className='home-title'>Gestión de Reseñas</label>
     </div>
      {loading ? (
        <Spin size="large" /> // Utiliza el Spin de Ant Design en lugar del Spinner personalizado
      ) : (
        <div className="reviews-container">
          <List
            grid={{ gutter: 16, column: 1 }}
            dataSource={reviews}
            renderItem={review => (
              <List.Item>
                <Card
                   title={`Nombre usuario/a: ${review.name}`}
                  bordered={true}
                  className={review.status === 'pending' ? 'pending-review' : 'approved-review'}
                  extra={review.status === 'pending' && (
                    <Button type="primary" onClick={() => approveReview(review.id)}>
                      Aprobar
                    </Button>
                  )}
                >
                  <p><strong>Comentario:</strong> {review.comment}</p>
                  <p><strong>Calificación:</strong> {review.rating}</p>
                  <p><strong>Estado:</strong> {review.status}</p>
                  <Button danger onClick={() => deleteReview(review.id)}>
                    Eliminar
                  </Button>
                </Card>
              </List.Item>
            )}
          />
        </div>
      )}
    </div>
  );
}

export default ReviewsManager;
